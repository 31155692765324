<template>
  <!-- 修改付款申请页 -->
  <div class="table-responsive mt-5">
    <router-link :to="{ name: 'payments_spayments_list' }">
      <el-button size="mini">返回申请单列表</el-button>
    </router-link>
    <div class="text-center">
      <h3>修改申请付款</h3>
    </div>
    <table class="table table-bordered table-sm dark">
      <tbody>
        <tr>
          <td colspan="8" style="background-color: rgba(152, 150, 245, 0.05)">
            基本信息
          </td>
        </tr>
        <tr>
          <td class="text-center align-middle"><small>日期</small></td>
          <td>
            <el-date-picker
              v-model="formdata.sq_date"
              type="date"
              placeholder=""
              size="small"
              format="YYYY/MM/DD"
              value-format="YYYY-MM-DD"
            ></el-date-picker>
          </td>
          <td class="text-center align-middle"><small>部门</small></td>
          <td>
            <department-select
              @select-change="departmentselect"
              :placeholder="departmentplace"
            ></department-select>
          </td>
          <td class="text-center align-middle"><small>付款金额</small></td>
          <td>
            <el-input
              v-model="formdata.sq_amount"
              placeholder=""
              size="small"
            />
          </td>
          <td class="text-center align-middle"><small>付款账户</small></td>
          <td>
           <!--banksclass 1-表示收付款账户  2表示往来款账户 -->
            <sfbanks-select @select-change="sfbanksselect"
                            :placeholder="sfbankplace"
                            banksclass="1"   
            ></sfbanks-select>
          </td>
        </tr>
        <tr>
          <td class="text-center align-middle"><small>凭证编号</small></td>
          <td>
            <span>{{formdata.id_no}}</span>
          </td>
          <td class="text-center align-middle"><small>成本类别</small></td>
          <td>
            <select-cbfl :placeholder="cbfllaceholder" @select-change="chenbenfenliselect"></select-cbfl>
          </td>
          <td class="text-center align-middle"><small>供应商</small></td>
          <td>
            <supplier-info
              :category_ids="formdata.category_id"
              :placeholder="suppliplace"
              @select-change="supplierselect"
            ></supplier-info>
          </td>
          <td class="text-center align-middle"><small>所属合同</small></td>
          <td>
            <el-select
              v-model="contract_info"
              placeholder=""
              size="small"
              @change="contract_select"
            >
              <el-option
                v-for="item in contract_list"
                :key="item.id"
                :label="item.contract_name"
                :value="item.id"
              >
              </el-option>
            </el-select>
          </td>
        </tr>
        <tr>
          <td></td>
          <td></td>
          <td class="text-center align-middle"><small>收款户名</small></td>
          <td>
            <el-select
              v-model="sk_bank_name"
              placeholder="Select"
              size="small"
              @change="banks_select"
            >
              <el-option
                v-for="item in sk_bank_list"
                :key="item.id"
                :label="item.account_name"
                :value="item.id"
              >
              </el-option>
            </el-select>
          </td>
          <td class="text-center align-middle"><small>收款账号</small></td>
          <td>
            <samp>{{ formdata.supplier_banks_no }}</samp>
          </td>
          <td class="text-center align-middle">
            <small>收款开户行</small>
          </td>
          <td>
            <samp>{{ formdata.supplier_banks_name }}</samp>
          </td>
        </tr>

        <tr>
          <td>付款说明</td>
          <td colspan="7">
            <el-input
              v-model="formdata.fk_text"
              :rows="4"
              type="textarea"
              placeholder=""
            />
          </td>
        </tr>
      </tbody>
    </table>

    <!-- 确认新增合同弹框 -->
    <el-popconfirm
      title="您确定要保存吗？"
      confirm-button-text="确定"
      cancel-button-text="取消"
      confirm-button-type="primary"
      @confirm="addcontract"
    >
      <template #reference>
        <el-button
          type="primary"
          size="mini"
          style="margin-left: 48%"
          disabled
          >保存修改</el-button
        >
      </template>
    </el-popconfirm>

    <div>
      <el-button size="mini" @click="dialogVisible = true"
        >批量上传</el-button
      >

      <el-dialog v-model="dialogVisible" width="50%" @closed="getFileList">
        <div class="text-center">
          <file-upload
            :fileclass="fileclass"
            :prdclassid="rowid"
            urlpath="admin/AccessoryFile"
            filedir="spayments"
          ></file-upload>
        </div>
      </el-dialog>
      <el-table :data="tableData.data">
        <el-table-column prop="file_name" label="文件名称" width="300" />
        <el-table-column prop="create_time" label="上传时间" width="150" />
        <el-table-column label="操作" width="150" align="center">
          <template #default="scope">
            <el-button-group>
              <el-button size="small" plain @click="openPdfFile(scope.row.url_path)">查看</el-button>
              <el-button size="small" plain type="danger" @click="deletePdfFile(scope.row.id)">删除</el-button>
            </el-button-group>
          </template>
        </el-table-column>
      </el-table>
    </div>
  </div>

  <!-- 查看PDF文件 -->
        <el-dialog v-model="pdfurlVisible" width="100%"
                    fullscreen
                    @closed="pdfurl=''"
                    >
        <div class="text-center" style="height:2000px">
           <iframe :src="pdfurl" width="100%" height="100%"></iframe>
        </div>
      </el-dialog>
</template>

<script>
import axios from "axios";
import { ElMessageBox } from "element-plus";
export default {
  data() {
    return {
      cbfllaceholder:"",//成本分类默认值 修改前的值
      departmentplace:"",//部门分类默认值 修改前的值
      sfbankplace:"",//付款银行默认值 修改前的值
      suppliplace:"",//供应商默认值 修改前的值

      pdfurl:"",//PDF文件urlf地址
      pdfurlVisible:false,//查看显示PDF文件
    //分类ID     0-项目图片  1-付款合同  2-付款申请   3-发票   
    //           4-银行回单  5-收款合同  6-收款凭证  7-资金计划附件
      fileclass: "2", 
      dialogVisible: false, //是否打开上传模态框

      contract_info: "", //对应合同信息
      sk_bank_name: "", //收款账户名称

      contract_list: [], //合同列表
      sk_bank_list: [], //收款银行列表

      formdata: {
        id:window.sessionStorage.getItem("rowid"),
        id_no: "", //凭证编号
        sq_date: "", //日期
        fk_date:"9999-99-99",//付款日期
        department_id: "", //部门ID
        sq_amount: "", //付款金额
        sf_banks_id: "", //付款账户ID
        category_id: "", //成本ID
        supplier_info_id: "", //供应商ID
        fcontract_info_id: "", //合同ID
        supplier_banks_id: "", //收款银行ID
        proposer_id: JSON.parse(window.sessionStorage.getItem("user")).id, //申请人ID
        approver_id: "", //审批人ID
        cfo_id: "", //财务人ID/审核人ID
        registrant_id: "", //付款登记人ID
        resource_is: "", //资料齐否状态
        order_status: 1, //记录状态
        project_id: window.sessionStorage.getItem("project_ids"), //项目ID
        fk_text: "", //付款说明
        supplier_banks_no: "", //收款银行账号
        supplier_banks_name: "", //收款银行名称
        account_name:""  //收款户名
        

      },
      //附件列表数据
      tableData: {
        data: [],
      },
      //记录ID
      rowid:window.sessionStorage.getItem("rowid")
    };
  },
  created(){
      //加载单条记录 
     this.loadtabledata(this.rowid);
     //获取对应记录的附件
     this.getFileList();
  },
  watch: {},
  methods: {
    //单击部门控件事件
    departmentselect(val) {
      console.log("部门控件子组件传来的数据", val);
      this.formdata.department_id = val.id;
    },
    //单击付款账户控件事件
    sfbanksselect(val) {
      console.log("单击付款账户控件事件", val);
      this.formdata.sf_banks_id = val.id;
    },
    //单击成本分类控件事件
    chenbenfenliselect(val) {
      console.log("成本分类控件子组件传来的数据", val);
      this.selectChange(val);

      //改变分类需清空控件内容
      this.contract_info = "";
      this.contract_list = "";

      this.sk_bank_name = "";
      this.sk_bank_list = "";

      this.formdata.supplier_banks_no = "";
      this.formdata.supplier_banks_name = "";
    },

    //更改成本分类选项
    selectChange(itme) {
      //计算生成成本ID（一级分类id/二级分类id/...)
      let temp = "";
      for (let i = itme.length - 1; i >= 0; i--) {
          temp =itme[i] + "/" + temp;
      }
      temp = '/'+ temp;
      this.formdata.category_id = temp;
      console.log("////", this.formdata.category_id);
    },

    //单击供应商控件事件
    supplierselect(val) {
      this.contract_info = "";
      this.contract_list = val["FcontractInfos"];

      this.sk_bank_name = "";
      this.sk_bank_list = val["SupplierBanks"];

      this.formdata.supplier_banks_no = "";
      this.formdata.supplier_banks_name = "";
      console.log("供应商控件事件传来的数据", val);
      this.formdata.supplier_info_id = val.id;
    },

    //选择收款账户，触发事件
    banks_select(val) {
      this.sk_bank_list.forEach((dataval) => {
        if (val == dataval["id"]) {
          this.formdata.supplier_banks_no = dataval["account_no"];
          this.formdata.supplier_banks_name = dataval["banks"];
        }
      });
      this.formdata.supplier_banks_id = val;
    },

    //选择‘所属合同’，触发事件
    contract_select(val) {
      console.log("所属合同控件传来的数据", val);
      this.formdata.fcontract_info_id = val;
    },
    //保存修改后的付款申请记录
    addcontract() {
      console.log("保存修改后的付款申请记录", this.formdata);
      axios
        .post("admin/FkRequest/"+this.rowid+"/edit", this.formdata, {
          headers: {
            token: window.sessionStorage.getItem("token"),
          },
        })
        // .then((response) => {
        .then(() => {
          ElMessageBox.alert("修改成功！", "提示", {
            confirmButtonText: "OK",
          });
        })
        //.catch((error) => {
        .catch(() => {
          ElMessageBox.alert("失败！", "提示", {
            confirmButtonText: "OK",
          });
        })
        .then(() => {
          // 总是会执行
        });
    },

    //获取对应记录的附件
    getFileList() {
      axios
        .get("admin/AccessoryFile", {
          params: {
            fileclass_id: this.fileclass,
            record_id: this.rowid,
          },
          headers: {
            token: window.sessionStorage.getItem("token"),
          },
        })
        .then((response) => {
          console.log("******", response.data.data, "******");
          this.tableData.data = response.data.data;
        })
        .catch((error) => {
          console.log(error);
        })
        .then(() => {
          // 总是会执行
        });
    },
    //查看PDf
    openPdfFile(url){
      this.pdfurlVisible = true;
      this.pdfurl = url;
    },
    //加载单条记录 
     loadtabledata(rowid) {
       console.log("admin/FkRequest/"+rowid)
      axios
        .get("admin/FkRequest/"+rowid, {
          params: {
          },
          headers: {
            token: window.sessionStorage.getItem("token"),
          },
        })
        .then((response) => {
          console.log('!@!@!@!@!@!@',response.data.data[0]);
           //单要记录赋值
          if(response.data.data.length>0){
            this.fuzhione(response.data.data[0])
          }
          
        })
        .catch((error) => {
          console.log(error);
        })
        .then(() => {
          // 总是会执行
        });
    },
    //单要记录赋值
    fuzhione(onerocad){

      this.cbfllaceholder = onerocad.category_id    //成本s分类默认值 修改前的值
      this.departmentplace = onerocad.Departmentinfo.id.toString()   //部门分类默认值 修改前的值
      this.sfbankplace =  onerocad.SfBanksinfo.id.toString()   //付款银行默认值 修改前的值
      this.suppliplace = onerocad.SupplierInfo.supplier_name    //供应商默认值 修改前的值
      this.formdata.id_no = onerocad.id_no      //凭证编号
      this.formdata.sq_date = onerocad.sq_date  //日期
      this.formdata.sq_amount = onerocad.sq_amount  //付款金额

      if(onerocad.FcontractInfo!=null){
        this.contract_info = onerocad.FcontractInfo.contract_name    //合同ID
      }
        
        this.formdata.fk_text = onerocad.fk_text //付款说明
        this.sk_bank_name = onerocad.SupplierBanksinfo.account_name  //收款户名
        this.formdata.supplier_banks_name = onerocad.SupplierBanksinfo.banks   //收款银行名
        this.formdata.supplier_banks_no = onerocad.SupplierBanksinfo.account_no  //收款账号

    },
    //删除PDF文件
    deletePdfFile(rowid){
       axios
        .delete("admin/AccessoryFile/"+rowid, {
          params: {},
          headers: {
            token: window.sessionStorage.getItem("token"),
          },
        })
        .then((response) => {
          console.log("******11", response.data.data, "11******");
           this.getFileList()
        })
        .catch((error) => {
          console.log(error);
        })
        .then(() => {
          // 总是会执行
        });
    },
  },
};
</script>


<style>
</style>